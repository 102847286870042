body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.jss>tbody>tr>td, .jss>thead>tr>td {
  /* border-top: 1px solid var(--border_color);
  border-left: 1px solid var(--border_color); */
  background-color: #ffffff;
  /* color: var(--content_color); */
}

.jss_container input, .jss_container select, .jss_page {
  color: var(--header_color);
  background: #f8f8f8;
  border: 1px solid var(--border_color);
}
.jdropdown-default.jdropdown-focus .jdropdown-header.jdropdown-add {
  background-repeat: no-repeat;
  background-position-x: right;
}
.jdropdown-default .jdropdown-group-name {
  padding-left: 8px;
  font-weight: bold;
  text-align: left;
  padding-bottom: 7px;
}